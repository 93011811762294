var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "main-div",
      class: {
        "input-group-focus": _vm.focused,
        "has-label": _vm.label,
      },
      staticStyle: { "margin-bottom": "1rm" },
    },
    [
      _vm._t("label", function () {
        return [
          _vm.label
            ? _c("label", { staticClass: "input-label" }, [
                _c("span", [_vm._v(_vm._s(_vm.label))]),
                _vm.required
                  ? _c("i", { staticClass: "required-field float-left" })
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      }),
      _c(
        "div",
        {
          staticClass: "input-wrapper mb-0",
          class: {
            "input-group": _vm.hasIcon,
          },
        },
        [
          _vm._t("addonLeft", function () {
            return [
              _vm.addonLeftIcon
                ? _c("span", { staticClass: "input-group-prepend" }, [
                    _c(
                      "div",
                      {
                        staticClass: "input-group-text",
                        class: {
                          "error-brdr": _vm.error,
                          "success-brdr": !_vm.error && _vm.touched,
                        },
                      },
                      [_c("i", { class: _vm.addonLeftIcon })]
                    ),
                  ])
                : _vm._e(),
            ]
          }),
          _vm._t("default", function () {
            return [
              _c(
                "Select",
                _vm._g(
                  {
                    directives: [
                      {
                        name: "focus",
                        rawName: "v-focus",
                        value: _vm.focus,
                        expression: "focus",
                      },
                    ],
                    ref: "select",
                    staticClass: "select-primary",
                    class: {
                      "error-border": _vm.hasValidation && _vm.error,
                      "success-border":
                        _vm.hasValidation && !_vm.error && _vm.touched,
                    },
                    attrs: {
                      filterable: "",
                      value: _vm.value,
                      placeholder: _vm.placeholder,
                      disabled: _vm.disabled,
                      "filter-method": _vm.filterMethod,
                      multiple: _vm.multiple,
                      "allow-create": _vm.allowCreate,
                      "collapse-tags": _vm.collapseTags,
                      clearable: _vm.clearable,
                      remote: _vm.remote,
                      "remote-method": _vm.executeRemoteMethod,
                      loading: _vm.loading,
                      "popper-class": _vm.popperClass,
                    },
                  },
                  _vm.listeners
                ),
                [
                  _vm.multiple && _vm.showSelectAll
                    ? _c(
                        "Option",
                        {
                          attrs: { label: "Select All", value: _vm.SELECT_ALL },
                        },
                        [
                          _c(
                            "button",
                            { staticClass: "btn btn-primary btn-sm" },
                            [_vm._v("Select All")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.multiple && _vm.showClear
                    ? _c(
                        "Option",
                        { attrs: { label: "Clear", value: _vm.CLEAR } },
                        [
                          _c(
                            "button",
                            { staticClass: "btn btn-primary btn-sm" },
                            [_vm._v("Clear")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._t("options", function () {
                    return _vm._l(_vm.options, function (option) {
                      return _c("Option", {
                        key: option.value,
                        staticClass: "select-primary",
                        attrs: {
                          label: option.label,
                          value: option.value,
                          disabled: option.disabled,
                        },
                      })
                    })
                  }),
                ],
                2
              ),
            ]
          }),
          _vm.hasValidation && !_vm.error && _vm.touched
            ? _c(
                "span",
                { staticClass: "success-icon hidden-xs d-none d-lg-block" },
                [
                  _c("i", {
                    staticClass: "fa fa-check-circle",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              )
            : _vm.error
            ? _c("span", { staticClass: "error-icon" }, [
                _c("i", {
                  staticClass: "fa fa-exclamation-triangle",
                  attrs: { "aria-hidden": "true" },
                }),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm.error || _vm.$slots.error
        ? _vm._t("error", function () {
            return [
              _c("i", { staticClass: "error-msg" }, [
                _vm._v(_vm._s(_vm.error)),
              ]),
            ]
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }