<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <slot name="sidebar" />
    <div id="main-panel" class="main-panel" :data="sidebarBackground">
      <router-view name="header"></router-view>
      <div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
        <div class="row">
            <div
              class="col-12"
              v-for="notification in getUserNotifications"
              :key="notification._id"
            >
              <Notification
                v-if="notification.app === getSelectedApp"
                class="mb-3"
                style="top: unset; cursor: pointer"
                :type="notification.severity"
                icon="fa fa-bell"
                ackButtonStyle="position: relative; margin-right: 40px;font-size: 12px;border-radius: 10px; top: -25px;"
                :showAck="!notification.hideAck"
                :showClose="!notification.hideClose"
                :timeout="0"
                :message="notification.message"
                :link="notification.link"
                :copy="notification.copy"
                :closeOnClick="false"
                @ack="handleNotificationAck(notification)"
                @close="handleNotificationClose(notification._id)"
              />
            </div>
          </div>
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer
        v-if="$route.meta.showFooter && brandingGetter.features.footer.enabled"
      ></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { mapGetters } from 'vuex';
import { ZoomCenterTransition } from 'vue2-transitions';
import ContentFooter from '../../../common/pages/Layout/ContentFooter.vue';
import Notification from '../../../common/components/NotificationPlugin/Notification.vue';
import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import { SWEET_ALERT_WARNING_CONFIG } from '@/common/helpers/sweet-alert-config';
import {
  SET_ORG_STEP_ONE,
  SET_ORG_STEP_TWO,
  SET_ORG_STEP_FOUR_MODERN
} from '@/o365/store/wizards/mutations-types';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: { ContentFooter, ZoomCenterTransition, Notification },
  computed: {
    ...mapGetters([
      'loggedInUser',
      'getThemeSettings',
      'brandingGetter',
      'getUserNotifications',
      'getSelectedApp',
      'isAuthenticated',
      'getCompanies',
      'getO365OrganizationJobs',
      'getWizardPromptDismissed',
      'getOrgWizStepOne'
    ]),
    sidebarBackground() {
      return this.getThemeSettings ? this.getThemeSettings.sidebarColor : 'blue';
    }
  },
  created() {
    this.$store.dispatch('GET_USER_NOTIFICATIONS', {userId: this.loggedInUser.id}).then(() => {
      if (this.loggedInUser.acl.some(acl => acl.role === 'sub-sp')) {
        const url = `${location.protocol}//${this.brandingGetter.domain}/o365/register/${this.loggedInUser.id}`;
        const notification = {
          app: 'o365',
          userId: this.loggedInUser.id,
          event: 'invite-users',
          message: `Please invite users to join: ${url}`,
          severity: 'info',
          hideAck: true,
          hideClose: true,
          link: url,
          copy: true
        }
        this.$store.dispatch('SET_USER_NOTIFICATIONS', [notification, ...this.getUserNotifications]);
      }
    });
  },
  mounted() {
    setTimeout(() => {
      if (this.isAuthenticated && !this.loggedInUser?.passwordReset && !this.getWizardPromptDismissed) {
        this.validateJobsAndOrgs();
      }
    }, 500);
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('sidebar-wrapper');
        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    },
    handleNotificationAck(notification) {
      const data = {
        notificationId: notification._id,
        userId: this.loggedInUser.id,
        event: notification.event,
        username: this.loggedInUser.username
      };
      this.$store.dispatch('ACK_NOTIFICATION', data);
    },
    handleNotificationClose(notificationId) {
      let removedAckNotifications = this.removeByAttr(
        this.getUserNotifications,
        '_id',
        notificationId
      );
      this.getUserNotifications = removedAckNotifications;
      this.$store.dispatch('SET_USER_NOTIFICATIONS', this.getUserNotifications);
    },
    removeByAttr(arr, attr, value) {
      let i = arr.length;
      while (i--) {
        if (
          arr[i] &&
          arr[i].hasOwnProperty(attr) &&
          arguments.length > 2 &&
          arr[i][attr] === value
        ) {
          arr.splice(i, 1);
        }
      }
      return arr;
    },
    validateJobsAndOrgs() {
      if (this.getSelectedApp === 'o365') {
        if (this.loggedInUser.ackO365TermsOfUse && !this.loggedInUser.hasOrganizations) {
          swal
            .fire({
              text: `Please start by creating a new Organization`,
              ...SWEET_ALERT_WARNING_CONFIG,
              confirmButtonText: 'Continue',
              showCancelButton: false,
              showCloseButton: true,
            })
            .then(({isConfirmed}) => {
              this.$store.commit('user/SET_WIZARD_PROMPT_DISMISSED', true);
              if (isConfirmed) {
                const params = {
                  'applications.office365.enabled': true
                };
                this.$store
                  .dispatch('GET_COMPANIES', params)
                  .then(async () => {
                    const company = this.getCompanies.data[0];
                    const companyName = company.name;
                    const spName = this.brandingGetter.name.replace(/\s+/g, '-').toUpperCase();
                    this.$store.commit(SET_ORG_STEP_ONE, {
                      type: 'Office365',
                      isExchangeOnline: true,
                      isSharePointOnline: true,
                      isTeamsOnline: true,
                      isTeamsChatsOnline: false,
                      companyObjId: company._id,
                      companyName,
                      id: this.getOrgWizStepOne.id,
                      name: this.getOrgWizStepOne.name
                    });
                    this.$store.commit(SET_ORG_STEP_TWO, {
                      region: 'Worldwide',
                      auth: 'modern',
                      legacy: false
                    });
                    this.$store.commit(SET_ORG_STEP_FOUR_MODERN, {
                      newApplicationName: `${spName}-${companyName}`,
                      sharePointOnline: {}
                    });
                    this.$router.push({
                      name: 'OrganizationFiveModernAuth',
                      params: { companyObjId: company._id, companyName }
                    });
                  })
              }
            });
        } else {
          if (this.loggedInUser.ackO365TermsOfUse && !this.loggedInUser.hasJobs) {
            swal
              .fire({
                text: `Please create a new Job under your Organization`,
                ...SWEET_ALERT_WARNING_CONFIG,
                confirmButtonText: 'Continue',
                showCancelButton: false,
                showCloseButton: true
              })
              .then(({isConfirmed}) => {
                this.$store.commit('user/SET_WIZARD_PROMPT_DISMISSED', true);
                if (isConfirmed) {
                  let loader = this.$loading.show();
                  const params = {
                    'applications.office365.enabled': true
                  };
                  this.$store
                    .dispatch('GET_COMPANIES', params)
                    .then(async () => {
                      if (this.getCompanies.count > 0) {
                        this.selectedCompanyId = this.getCompanies.data[0]._id;
                        const company = this.getCompanies.data[0];
                        if (company.applications.office365?.organizations.length) {
                          const loader = this.$loading.show();
                          try {
                            await this.$store.dispatch('GET_O365_ORGANIZATION_JOBS', {
                              organizationId: company.applications.office365?.organizations[0].id
                            }).then(() => {
                              if (!this.getO365OrganizationJobs.length) {
                                this.$router.push({
                                  name: 'JobOne',
                                  params: { organizationId: company.applications.office365?.organizations[0].id }
                                });
                              }
                            })
                          } catch (e) {
                            console.log(e);
                          } finally {
                            loader.hide();
                          }
                        }
                      }
                    })
                  .finally(() => loader.hide());
                }
              })
          }
        }
      }
    }
  },
  watch: {
    isAuthenticated: function(value) {
      if (value && !this.getWizardPromptDismissed) {
        this.validateJobsAndOrgs();
      }
    },
    'loggedInUser.passwordReset': function (value) {
      if (!value && this.isAuthenticated && !this.getWizardPromptDismissed) {
        this.validateJobsAndOrgs();
      }
    }
  }
};
</script>

<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
